<style scoped>
  /* .loginRegBox {
    background: #fff url(../assets/image/phoneloginback.png) center top no-repeat;
    background-position: right top;
  } */
  .header .section.title {
    height: 34px;
    position: relative;
  }

  .header .section.title>a,
  .header .section.title>input[type=button] {
    display: block;
    position: absolute;
  }

  .header .section.title>a.return {
    display: block;
    width: 24px;
    left: 10px;
    background: url(../assets/image/loginreturnwhite.png) center left no-repeat;
    background-size: 24px 24px;
    position: absolute;
    top: 0px;
    height: 24px;
    text-indent: -999999px;
    margin-top: 10px;
  }

  .header .section.title .titleR.btn {
    right: 15px;
    height: 30px;
    line-height: 30px;
    top: 10px;
    text-indent: 0;
    font-size: 0.875rem;
    padding: 0 15px;
    background: transparent !important;
    border: 1px solid #333;
    color: #333;
    border-radius: 5px;
  }

  .loginForm {
    /* padding-top: 20px; */
  }

  .loginRegBox .loginForm h3 {
    text-align: center;
    font-weight: normal;
    color: #a1a1a1;
    font-size: 1rem;
    margin: 20px 0;
  }

  .loginRegBox .login {
    padding: 40px 20px 0;
  }

  .loginBox {
    margin-top: 63px;
  }

  .login .loginBox .row {
    border-bottom: 1px solid #dcdcdc;
    /* height: 55px; */
    position: relative;
    padding: 0 0px 0px 37px;
  }

  .login .loginBox .row>p {
    position: absolute;
    left: 0;
    top: 18px;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
  }

  .login .loginBox .row>p>img {
    margin-left: 0px;
  }

  .login .loginBox .row>img {
    position: absolute;
    left: 0px;
    top: 13px;
    height: 26px;
    width: 26px;

  }

  .login .loginBox .row input[type=text],
  .login .loginBox .row input[type=tel],
  .login .loginBox .row input[type=password] {
    padding: 15px 0 14px 0 !important;
    line-height: 21px !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 15px;
    background: transparent;
    color: #B9B9B9;
  }

  .login .loginBox .row input.username {
    width: 100%;
  }

  .login .loginBox .row a.deleteInput {
    position: absolute;
    right: 0;
    top: 20px;
  }

  .login .showPsw,
  .login .hidePsw {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 26px;
    line-height: 26px;
    margin: 17px 0;
    text-align: center;
    border-radius: 3px;
    color: transparent;
  }

  .login .showPsw {
    background: url(../assets/image/hidepw.png) no-repeat right center;
    background-size: 25px auto;
  }

  .login .hidePsw {
    background: url(../assets/image/showpw.png) no-repeat right center;
    background-size: 23px auto;
  }

  .forget_pw {
    height: 24px;
    line-height: 24px;
    margin-top: 5px;
  }

  .forget_pw a {
    color: #434a54;
    font-size: 0.875rem;
    color: #a1a1a1;
  }

  .login .btnbottom {
    /* padding: 10px 0; */
    margin-top: 14px;
  }

  .btnbottom a,
  .btnbottom input[type=button] {
    text-align: center;
    background: #547CFF;
    border-radius: 5px;
    border: none;
    display: block;
    width: 100%;
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    /* font-family: 苹方-简; */
    font-weight: normal;
    color: #FFFFFF;
    opacity: 1;
  }

  .loginBox .row.code input[type=button] {
    position: absolute;
    right: 0;
    width: 90px;
    height: 34px;
    line-height: 34px;
    font-size: 0.875rem;
    text-align: center;
    background: #5297ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    -webkit-appearance: none;
    top: 13px;
  }

  input[type=checkbox],
  input[type=radio] {
    vertical-align: middle;
  }

  .checkbox input[type=checkbox] {
    opacity: 0;
    width: 15px;
    height: 15px;
    display: block;
    margin: 0;
  }

  .checkbox {
    display: block;
    float: left;
    width: 15px;
    height: 15px;
    background: url(../assets/image/click_true.png) no-repeat;
    background-size: auto 15px;
    position: relative;
    margin-right: 5px;
    top: 2px;
  }

  .checkbox.on {
    background: url(../assets/image/loginhook.png) no-repeat;
    background-size: auto 15px;
  }

  .phone_title {
    height: 28px;
  }

  .phone_title_text {
    font-size: 13px;
    font-weight: 400;
    color: #657083;
    margin-top: 12px;
  }

  .phone_chec {
    margin-top: 28px;
  }

  .User_agreement {
    color: #547CFF;
  }

  .Verification_code {
    border: none;
    float: right;
    height: 33px;
    background: #547CFF;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 400;
    /* line-height: 10px; */
    color: #FFFFFF;
    /* line-height: 25px; */
    /* padding: 4px 11px 4px 13px; */
    margin: 13px 0;
    width: 84px;
    height: 25px;
  }

  .password {
    width: 100px;
  }

  .tip_mssge {
    font-size: 13px;
    font-weight: 400;
    color: #E94140;
    margin-top: 11px;
  }

  /* 固定了头部之后有些页面发生的问题修改 */
  .header {
    height: initial !important;
  }

  .header .section.title {
    height: initial !important;
    /* line-height: 50px; */
    position: initial !important;
  }

  /* 固定了头部之后有些页面发生的问题修改 */
  /* body{
    background: url(../assets/image/loginbgsm.png) no-repeat 100%;
  } */
  /* .login_bg{
    width: 100%;
        height: 100%;
        background: url(../assets/image/loginbgsm.png) 100%;
        position: fixed;
  } */
  .loginRegBox {
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
  }

  .phone_title_text1 {
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
  }

  .phone_title_text1:nth-of-type(1) {
    margin-top: 15%;
  }

  .phone_title_text1:nth-of-type(2) {
    margin-top: 12px;
  }

  .phone_title_text1:nth-of-type(3) {
    margin-top: 12px;
  }

  .loginBox {
    margin-top: 10%;
  }

  .phone_title_text2 {
    line-height: 2;
  }

  .cordid {
    border-radius: 5px;
    width: 100%;
    height: auto;
    background: url(../assets/image/usercord.png) no-repeat;
    background-size: 100%;
    margin-top: 11%;
    padding: 9% 0 9% 6%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .cordid_fl {
    float: left;
    overflow: hidden;
    /* height: 100%; */
    width: 32%;
    margin-right: 7%;
  }

  .cordid_fl>img {
    width: 100%;
  }

  .cardtop {
    font-size: 13px;
    font-weight: 400;
    color: rgba(255, 255, 255, .48);
  }

  .cordid_fl2 {
    float: left;
  }

  .cardbottom {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
  }

  .cardtop {
    margin-bottom: 1%;
  }

  .cardbottom {
    margin-bottom: 8%;

  }

  .cardtip {
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    color: #FFFFFF;
    margin-top: 24%;
  }

  .menubox {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(255, 255, 255, .0);
  }
</style>
<template>
  <div class="loginRegBox" @click="loginRegBoxcli"
    :style="{height: windowHeight + 'px','background': 'url(' + srcImgUrl + ')'}">
    <div class="tab">
      <!-- 登录 -->
      <div class="tab_box">
        <div class="header bookreviews">
          <div class="section title">
            <a @click="goReturn" class="return">返回</a>
          </div>
        </div>
        <form class="loginForm">
          <div class="login">
            <!-- <div class="phone_title"><img width="48" height='28' src="../assets/image/phone_title.png" alt=""></div> -->
            <!-- <div class="phone_title_text">登录后即可创造属于你的梦想故事</div> -->
            <div class="phone_title_text1" v-show="text1">
              亲爱的旅者
            </div>
            <div class="phone_title_text1 phone_title_text2" v-show="text2">
              我在你的身上，看到不属于这个世界的波动，来领取你的身份ID吧
            </div>
            <div v-show="login_input">
              <div class="loginBox">
                <div class="row">
                  <p><img src="" height="22"></p>
                  <img src="../assets/image/phoneshu.png" height="22">
                  <input class="username" name="username" v-model="loginInfo.username"
                    @keyup="setInput(loginInfo.userName)" type="text" placeholder="请输入手机号" maxlength="11">
                  <!--<a class="deleteInput" v-if="loginClear" @click="clearLogin"><img src="../assets/image/login_clear.png" height="20"></a>-->
                </div>
                <div class="row row2">
                  <!-- <p>验证码</p> -->
                  <img src="../assets/image/loginEmail.png" height="22">
                  <input class="password psw" type="text" v-model="loginInfo.code" name="password" placeholder="请输入验证码"
                    maxlength="6">
                  <a><input type="button" class="Verification_code" @click="getCode" :disabled="codeFlag"
                      v-model="codeStr"></a>
                </div>
              </div>
              <div class="tip_mssge" v-show="tip_mssge_show">{{tip_mssge}}</div>
              <div class="btnbottom js-tablist">
                <a @click="goLogin">领取</a>
              </div>

              <div class="phone_chec">
                <p style="display: inline-block;">
                  <span :class="isAgree ? 'checkbox on': 'checkbox'">
                    <input type="checkbox" name="mxDeal" id="mxDeal" @click="agree">
                  </span>
                  <a style="color:#CBCBCB;" @click="goAgree">已阅读，并同意<span class="User_agreement"
                      @click="goAgree">《梦想世界用户创作协议》</span></a>
                </p>
              </div>
            </div>
            <!-- 登陆成功显示的身份卡片 -->
            <div class="cardtip" v-show="isreceive">亲爱的旅者，你已经领取过身份了哦</div>
            <div class="cordid" v-show="rolecard">
              <div class="cordid_fl">
                <img src="../assets/image/roleimage.png" alt="">
              </div>
              <div class="cordid_fl2">
                <div class="identity cardtop">
                  身份
                </div>
                <div class="traveler cardbottom">
                  旅行者
                </div>
                <div class="idcard cardtop">
                  身份ID
                </div>
                <div class="idcard_num cardbottom">
                  u20000049435154
                </div>
                <div class="address cardtop">
                  住址
                </div>
                <div class="ress cardbottom">
                  梦想世界创作星球
                </div>
              </div>
            </div>
          </div>
          <agree v-if="agreeShow" @titleFnReturn="closeAgree"
            style="position: fixed;top: 0;left: 0;right: 0; bottom: 0;overflow-y: scroll;overflow-x: hidden;background: #fff;">
          </agree>
        </form>
      </div>
    </div>
    <!-- 卡片的蒙层 -->
    <div class="menubox" @click="cardmenuclick" v-show="cardmenu"></div>
    <!-- 点击屏幕控制文字显示 -->
    <!-- <input type="text" :value="showvalue"> -->
  </div>
</template>
<script>
import agree from '../views/agreement.vue'
// import qs from 'qs'
var ua = navigator.userAgent.toLowerCase()

function setupWebViewJavascriptBridge (callback) {
  /* eslint-disable */
    if (window.WebViewJavascriptBridge) {
      return callback(WebViewJavascriptBridge)
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback)
    }
    window.WVJBCallbacks = [callback]
    var WVJBIframe = document.createElement('iframe')
    WVJBIframe.style.display = 'none'
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
    document.documentElement.appendChild(WVJBIframe)
    setTimeout(function() {
      document.documentElement.removeChild(WVJBIframe)
    }, 0)
  }
  var userData = {}
  export default {
    name: 'home',
    components: {
      agree
    },
    data() {
      return {
        // appShowLoad: false,
        agreeShow: false,
        // showLogin: true,
        // hideLoginPsw: true,
        // hideRegPsw: true,
        // loginClear: false,
        // regClear: false,
        isAgree: false,
        loginInfo: {
          username: '',
          code: ''
        },
        codeStr: '跨时空验证',
        codeFlag: false,
        codeWait: 60,
        tip_mssge: "",
        tip_mssge_show: false,
        username: '',
        smscode: '',
        sUserId: '',
        params: '',
        windowHeight: document.documentElement.clientHeight,
        srcImgUrl: require('../assets/image/loginbgsm.png'),
        text1: false,
        text2: false,
        login_input: false,
        rolecard: false,
        isreceive: false,
        cardmenu: false,
        clickNum: 1
      }
    },
    computed: {},
    created() {
      setTimeout((e)=> {
        this.clickCom()
      }, 3000)
      setTimeout((e)=> {
        this.clickCom()
      }, 5000);
       this.clickCom()
      // 根据设备选择对应背景显示
      if (this.windowHeight > 736) {
        this.srcImgUrl = require('../assets/image/loginbgbig.png')
      }
      // username=&smscode=
      this.username = this.$route.query.username;
      this.smscode = this.$route.query.smscode;
      this.sUserId = this.$route.query.sUserId;
      this.params = this.$route.query.params;
      this.loginfn()
    },
    mounted() {
      var that = this
      if (ua.match(/zkdreamreading/i) == 'zkdreamreading') {
        document.title = ''
        that.appShowLoad = true
        setupWebViewJavascriptBridge(function(bridge) {
          bridge.registerHandler('getUserInfos', function(data, responseCallback) {
            userData = data
            // that.$store.dispatch('home/loginTest', { 'username': userData.userId, 'password': '123456', 'token': userData.userToken, 'type': 'app', 'userdata':　userData, 'router': this.$router, 'toast': this.$toast })
          })
        })
        setTimeout(function() {
          that.$store.dispatch('home/loginTest', {
            'username': userData.userId,
            'password': '123456',
            'token': userData.userToken,
            'type': 'app',
            'userdata': userData,
            'router': this.$router,
            'toast': this.$toast
          })
        }, 100)
      } else if (ua.match(/mxsj/i) == 'mxsj') {
        that.appShowLoad = true
        userData = JSON.parse(android.getLoginData())
        // alert(userData.userId)
        that.$store.dispatch('home/loginTest', {
          'username': userData.userId,
          'password': '123456',
          'token': userData.userToken,
          'type': 'app',
          'userdata': userData,
          'router': this.$router,
          'toast': this.$toast
        })
      }
    },
    methods: {
      clickCom() {
        if (this.clickNum == 1) {
          this.text1 = true
          this.clickNum++;
          return;
        }
        if (this.clickNum == 2) {
          this.text2 = true
          this.clickNum++;
          return;
        }
        if (this.clickNum == 3) {
          this.login_input = true
          this.clickNum++;
          return;
        }
      },
      loginRegBoxcli() {
        if (this.clickNum <= 3) {
          this.clickCom()
        }
      },
      cardmenuclick() {
        // console.log(123)
        this.$store.dispatch('home/loginCode', {
          'username': this.loginInfo.username,
          'smscode': this.loginInfo.code,
          'sUserId': this.sUserId,
          'params': this.params,
          'token': '',
          'type': 'web',
          'router': this.$router,
          'toast': this.$toast
        })
      },
      loginfn() {
        if (this.username != null && this.smscode != null && this.username.length > 0 && this.smscode.length > 0) {
          // console.log("mxsj login")
          this.$store.dispatch('home/loginCode', {
            'username': this.username,
            'smscode': this.smscode,
            'sUserId': this.sUserId,
            'params': this.params,
            'token': '',
            'type': 'web',
            'router': this.$router,
            'toast': this.$toast
          })
        }
      },
      goReturn() {
        window.history.back(-1)
      },
      setInput(value) {
        /*if (value !== '') {
          this.loginClear = true
        } else {
          this.loginClear = false
        }*/
      },
      clearLogin() {
        this.loginInfo.username = ''
        this.loginClear = false
      },
      // 登录
      goLogin() {
        if (this.loginInfo.username === '') {
          this.tip_mssge_show = true;
          this.tip_mssge = '请输入登录手机号码'
          // this.$toast()
        } else if (this.loginInfo.code === '') {
          this.tip_mssge_show = true;
          this.tip_mssge = '请输入验证码'
          // this.$toast()
        } else if (!this.isAgree) {
          // this.$toast()
          this.tip_mssge_show = true;
          this.tip_mssge = '请阅读并同意《梦想世界用户创作协议》'
        } else {
          // 登陆成功
          // this.text1 = false
          // this.text2 = false
          // this.login_input = false
          // this.rolecard = true
          // this.cardmenu = true
          // setTimeout((e)=>{
          this.$store.dispatch('home/loginCode', {
            'username': this.loginInfo.username,
            'smscode': this.loginInfo.code,
            'sUserId': this.sUserId,
            'params': this.params,
            'token': '',
            'type': 'web',
            'router': this.$router,
            'toast': this.$toast
          })
          // },3000)
          // this.$store.dispatch('home/loginTest', { 'username': this.loginInfo.username, 'password': this.loginInfo.code, 'token': '', 'type': 'web', 'router': this.$router, 'toast': this.$toast })
        }
      },
      // 获取验证码
      getCode() {
        if (this.loginInfo.username === '') {
          // this.$toast()
          this.tip_mssge_show = true;
          this.tip_mssge = '请输入手机号码'
        } else if (!isNaN(this.loginInfo.username) && !this.loginInfo.username.match(
            /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/)) {
          // this.$toast('')
          this.tip_mssge_show = true;
          this.tip_mssge = '手机号码格式不正确'
        } else {
          this.axios({
            method: 'get',
            url: '/v1/getUserId',
            params: {
              username: this.loginInfo.username,
            }
          }).then((res) => {
            // console.log(res.data)
            if (res.data == null || res.data.length == 0) {
              // console.log(1111)
              sessionStorage.setItem("isNewUser", true);
            } else {
              // console.log(222)
              sessionStorage.setItem("isNewUser", false);
            }
          })
          this.axios({
            method: 'get',
            url: '/v1/nc/getCode',
            params: {
              username: this.loginInfo.username,
              type: 1
            }
          }).then((res) => {
            if (res.data.head.flag === 0) {
              this.codeFlag = true
              this.codeWait = 60
              this.time()
            } else {
              this.$toast(res.data.head.desc)
            }
          })
        }
      },
      time() {
        var that = this
        if (this.codeWait === 0) {
          this.codeFlag = false
          this.codeStr = '重发验证码'
          this.codeWait = 60
        } else {
          this.codeFlag = true
          this.codeStr = this.codeWait + '秒后重发'
          this.codeWait--
          setTimeout(function() {
            that.time()
          }, 1000)
        }
      },
      agree() {
        this.isAgree = !this.isAgree
      },
      goAgree() {
        this.agreeShow = true
      },
      closeAgree() {
        this.agreeShow = false
      }
    }
  }
</script>
